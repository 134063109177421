import { useEffect, useState } from "react";
import TableContainer from "../form/TableContainer";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import {
    $getStatisticAverageHours, $getStatisticRating,
    $getStatisticSpendHours,
    $getStatisticVisitorCount
} from "../../api/requests/reports";

export const RatingReport = () => {
    const dateOperation = new DateOperations();
    const userData = useSelector(GetCurrentUserData);

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const [ isLengthList, setIsLengthList ] = useState<boolean>(false);

    const headerTable = [
        {
            key: 'name',
            title: 'Название',
            width: '33.33%'
        },
        {
            key: 'city',
            title: 'Город',
            width: '33.33%'
        },
        {
            key: 'rating',
            title: 'Рейтинг',
            width: '33.33%'
        },
    ];

    const [ contentTable, setContentTable ] = useState<any[]>([]);

    const onChangeResponseItem = (item: any): any => {
        return {
            name: item.center.name || '',
            city: item.center.city || '',
            rating: item.generalScore || 0,
        }
    }

    useEffect(() => {
        userData && init();
    }, [userData]);

    function init() {
        const requestObj = {
            fromDate: dateOperation.getISODateTimeByObj(dateStart),
            toDate: dateOperation.getISODateTimeByObj(dateEnd),
        }

        setIsLengthList(false);

        $getStatisticRating(requestObj).then(res => {
            if (!res.length) return setIsLengthList(true);

            setContentTable(res.map(i => i && onChangeResponseItem(i)))
        })
    }

    return (
        <div className='reports__shift'>
            <div className='reports__filter'>
                <div className='reports__filter-dates'>
                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={ dateStart }
                        onChangeDate={ (date) => {
                            setDateStart(date)
                        } }
                    />

                    <span className='reports__filter-line'></span>

                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={ dateEnd }
                        onChangeDate={ (date) => {
                            setDateEnd(date)
                        } }
                    />
                </div>

                <div className='reports__filter-buttons'>
                    <Button
                        text={ 'Применить' }
                        className='btn btn-primary'
                        onClick={ init }
                    />

                    {/*<FilterSelect*/}
                    {/*    placeholder='Выбор'*/}
                    {/*    options={ [ {label: 'first', value: 0}, {label: 'second', value: 1} ] }*/}
                    {/*    onChange={ () => ('select') }*/}
                    {/*    className='reports__filter-selection'*/}
                    {/*/>*/}
                </div>
            </div>

            { !isLengthList && <h1 className='reports__shift-title'>Отчет по рейтингу городов</h1> }

            { !isLengthList ? (
                    <div className='reports__shift-content'>
                        <TableContainer
                            sortable={ false }
                            header={ headerTable }
                            content={ contentTable }
                            isLastRightText={ false }
                        />
                    </div>
                ) : (
                    <div className='reports__shift-notFound'>
                        За выбранный период данных нет.
                    </div>
                )
            }
        </div>
    )
}
