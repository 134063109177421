import React, { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $getListZone } from "../../api/requests/zone";
import ImageField from "../form/ImageField";
import FilterSelect from "../FilterSelect";
import ImageToBase64 from "../../mixins/image-to-base64";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import { ToggleBlock } from "../form/ToggleBlock";
import { getZoneData } from "../../redux/slices/zoneSlice";

interface CreateGroupDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    type: string
}

const CreateGroupDialog: FC<CreateGroupDialogProps> = (
    {
        isShow,
        setIsShow,
        type
    }
) => {
    const imageToBase64 = new ImageToBase64();

    const isAdmin = useSelector(GetCurrentUserData)?.role === 1;
	const zoneList = useSelector(getZoneData);

    const [ name, setName ] = useState('');
	const [ maxDevice, setMaxDevice ] = useState('');
    const [ position, setPosition ] = useState('');
    const [ zoneValue, setZoneValue ] = useState<any>(null);
    const [ monthValue, setMonthValue ] = useState<any>(null);
    const [ image, setImage ] = useState<any>(null);
    const [ isCreatedMap, setIsCreatedMap ] = useState(false);

    const [ optionsZoneList, setOptionsZoneList ] = useState<any[]>([]);

    const [ valueDevice, setValueDevice ] = useState<any>(null);
    const [ valueColor, setValueColor ] = useState('');

    const [ isLoadingSave, setIsLoadingSave ] = useState<boolean>(false);

    const dateMonthList = [
        {
            label: 'Январь',
            value: 1
        },
        {
            label: 'Февраль',
            value: 2
        },
        {
            label: 'Март',
            value: 3
        },
        {
            label: 'Апрель',
            value: 4
        },
        {
            label: 'Май',
            value: 5
        },
        {
            label: 'Июнь',
            value: 6
        },
        {
            label: 'Июль',
            value: 7
        },
        {
            label: 'Август',
            value: 8
        },
        {
            label: 'Сентябрь',
            value: 9
        },
        {
            label: 'Октябрь',
            value: 10
        },
        {
            label: 'Ноябрь',
            value: 11
        },
        {
            label: 'Декабрь',
            value: 12
        },
    ];

    const typeDevice = [
        { value: 0, label: 'ПК', type: 'comp' },
        { value: 1, label: 'Консоль', type: 'console' },
        { value: 2, label: 'VR', type: 'vr' },
        { value: 3, label: 'Спортивные зоны', type: 'open-area' }
    ];

    const typeSportList = [
        { value: 3, label: 'Мини-футбол' },
        { value: 4, label: 'Стритбол' },
        { value: 5, label: 'Зона воркаут' },
    ];

    const isFormValid = () => {
        return !name.length;
    }

	useEffect(() => {
		if (zoneList && zoneList.length) {
			const updateList = [...zoneList].map(item => ({
				...item,
				label: item.name,
				value: item.id
			}))

			setOptionsZoneList(updateList)
		}
	}, [zoneList]);

    function onClickSave() {
        if (isFormValid()) return;

        setIsLoadingSave(true);

        const findTypeItem = typeDevice.find(i => i.type === type)

        const typeValue = findTypeItem ? ((findTypeItem.value === 3) ? +valueDevice.value : findTypeItem.value) : 0

        const requestData = {
            name,
            // position: +position,
            type: typeValue,
            months: monthValue && monthValue.map((i: any) => i.value),
            image: imageToBase64.getImageCheckValid(image),
			connectZoneIds: zoneValue ? [...zoneValue].map(item => item.value) : [],
			maxSelectedDeviceCount: +maxDevice,
            // color: valueColor
        }

        $getListZone(requestData).then(i => {
            setIsLoadingSave(false);

            if (!i) return;

            resetForm();
            setIsShow(true);
        })
    }

    function resetForm() {
        setName('');
        setImage(null);
        setMonthValue(null);
        setValueDevice(null);
        setValueColor('');
        setPosition('');
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                setIsShow();
                resetForm();
            }}
            label={ 'Создание' }
            closeIcon={ <Close/> }
        >
            {isAdmin && (
                <div className="edit__stocks__right-content">
                    <ImageField
                        label={ '150x150' }
                        upload={ image }
                        setUpload={ setImage }
                    />
                </div>
            )}

            <div className={'create__group'}>
                <TextField
                    label={'Название'}
                    imgRight={(
                        <p>{`${name.length}/100`}</p>
                    )}
                    value={name}
                    onChangeValue={setName}
                    maxLength={100}
                />

				<TextField
					label={'Максимальное количество устройств для бронирования'}
					type={"number"}
					value={maxDevice}
					onChangeValue={setMaxDevice}
					maxLength={10}
				/>

                {/*<TextField*/}
                {/*    type={'number'}*/}
                {/*    label={ 'Позиция' }*/}
                {/*    value={ position }*/}
                {/*    onChangeValue={ setPosition }*/}
                {/*/>*/}

                {(type === 'open-area') && (
                    <FilterSelect
                        placeholder={'Тип'}
                        value={valueDevice}
                        options={typeSportList}
                        className={'mb-3'}
                        onChange={(val) => setValueDevice(val)}
                    />
                )}

                <div>
                    <FilterSelect
                        isMulti
						className={'mb-3'}
                        placeholder={'Связать зоны'}
                        value={zoneValue}
                        options={optionsZoneList}
                        onChange={setZoneValue}
                    />
                </div>

                <div>
                    <FilterSelect
                        isMulti
                        className={''}
                        placeholder={'Какие месяцы доступны'}
                        value={monthValue}
                        options={dateMonthList}
                        onChange={setMonthValue}
                    />
                </div>

                {/*<div className={'mt-3 position-relative'}>*/}
                {/*    <input*/}
                {/*        type="color"*/}
                {/*        value={valueColor}*/}
                {/*        onChange={e => setValueColor(e.target.value)}*/}
                {/*        className={'form-color'}*/}
                {/*    />*/}
                {/*    <TextField*/}
                {/*        label={'Цвет'}*/}
                {/*        value={valueColor}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className={'mt-3'}>*/}
                {/*    <ToggleBlock*/}
                {/*        desc={'Создать карту'}*/}
                {/*        isChecked={isCreatedMap}*/}
                {/*        handleChange={setIsCreatedMap}*/}
                {/*    />*/}
                {/*</div>*/}

                <Button
                    loading={isLoadingSave}
                    text={'Сохранить'}
                    onClick={onClickSave}
                    className={'btn btn-primary w-100 justify-content-center'}
                />
            </div>
        </DialogContainer>
    )
}

export default CreateGroupDialog;
