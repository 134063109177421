type InputObject = {
    [key: string]: any; // Используйте 'any' для значений любого типа
};

export default class TransformObjArray {
    getValue = (value: any): string => {
        if (typeof value === 'object' && !(value instanceof Date)) return value.value;

        return value || ''
    };

	setNestedValue = (obj: any, key: string, value: any) => {
		const keys = key.split('.');
		let current = obj;

		keys.forEach((k, index) => {
			if (index === keys.length - 1) {
				current[k] = value;
			} else {
				if (!current[k]) {
					current[k] = {};
				}
				current = current[k];
			}
		});
	};

    getRequestObj = (fieldList: any[]) => {
        return fieldList.reduce((acc, field) => {
            if (field.key && field.hasOwnProperty('value')) {
				const value = typeof field.value === 'boolean' || field.checked || field.checkbox
					? field.value || false
					: field.value ? this.getValue(field.value) : '';

				this.setNestedValue(acc, field.key, value);
            }

            return acc;
        }, {});
    };

    filterEmptyStringsAndFalsyValues(input: InputObject): InputObject {
        const result: InputObject = {};

        for (const key in input) {
            const value = input[key];

            if (value !== "" && value !== undefined && value !== null) {
                result[key] = value;
            }
        }

        return result;
    };

    formValidateByRequired = (requestObj: any, list: any[], isRequiredImage: boolean = true): any => {
        let isValid = undefined;

        Object.keys(requestObj).forEach(i => {
            list.forEach(item => {
                if ((item.key === i) && (item.required)) {
                    if (!item.value
                        && !item.checkbox
                        && item.type !== 'number'
                    ) isValid = item;

                    if (item.minLength && (item.value.length < item.minLength)) isValid = item;
                    if (item.maxLength && (item.value.length > item.maxLength)) isValid = item;
                }
            })

            if ((i === 'image') && (requestObj.image === null) && isRequiredImage) isValid = true;
        });

        return isValid;
    }
}
