import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemList } from './sidebar/Navigation';
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../redux/slices/userSlice";
import { changeNavigateDropdownItem, getNavigateData } from "../redux/slices/navigationSlice";
import { useAppDispatch } from "../redux/store";

const NavItem: FC<NavItemList> = (
    {
        link,
        icon,
        title,
        dropdownList
    }) => {
    const userData = useSelector(GetCurrentUserData);
    const isDropdownActive = useSelector(getNavigateData);

	const dispatch = useAppDispatch();

    const permissionsList = (userData && userData.permissions) && Object.keys(userData?.permissions) || [];

    function onClickDropdownList() {
		dispatch(changeNavigateDropdownItem(title))
    }

    if (link === '/') return (
        <a href={ link } className={ 'nav-link' }>
            <div className="nav-item">
                { typeof icon === 'string' ? (
                    <div className="icon">
                        <img src={ icon } alt="nav-icon"/>
                    </div>
                ) : (
                    <div className="icon">
                        { icon }
                    </div>
                ) }

                <div className='title'>
                    { title }
                </div>

                { dropdownList && (
                    <div className={ `nav-item-dropdown ${ (isDropdownActive === title ? 'active' : '') }` }>
                        <img src="/img/icon/form/arrow-bottom.svg" alt="arrow"/>
                    </div>
                ) }
            </div>
        </a>
    )

    if (dropdownList) return (
        <>
            <button
                className={ "nav-link w-100" }
                onClick={ onClickDropdownList }
            >
                <div className="nav-item">
                    { typeof icon === 'string' ? (
                        <div className="icon">
                            <img src={ icon } alt="nav-icon"/>
                        </div>
                    ) : (
                        <div className="icon">
                            { icon }
                        </div>
                    ) }

                    <div className='title'>
                        { title }
                    </div>

                    { dropdownList && (
                        <div className={ `nav-item-dropdown ${ (isDropdownActive === title ? 'active' : '') }` }>
                            <img src="/img/icon/form/arrow-bottom.svg" alt="arrow"/>
                        </div>
                    ) }
                </div>
            </button>

            { (isDropdownActive === title) && (
                <div>
                    { dropdownList.map((item, index) => {
                        if ((permissionsList && item.key) && permissionsList.includes(item.key)) {
                            if (typeof item.key === 'string' && !userData?.permissions[item.key].read) return null;
                        }

                        return (
                            <div
                                key={ `nav-child-${ index }` }
                                style={ {marginLeft: '15px'} }
                            >
                                <NavItem
                                    link={ item.link }
                                    icon={ item.icon }
                                    title={ item.title }
                                    dropdownList={ item.dropdownList }
                                />
                            </div>
                        )
                    }) }
                </div>
            ) }
        </>
    )

    return (
        <>
            <NavLink
                to={ link }
                className={ ({isActive}) =>
                    (isActive ? "nav-link current" : "nav-link") }
            >
                <div className="nav-item">
                    { typeof icon === 'string' ? (
                        <div className="icon">
                            <img src={ icon } alt="nav-icon"/>
                        </div>
                    ) : (
                        <div className="icon">
                            { icon }
                        </div>
                    ) }

                    <div className='title'>
                        { title }
                    </div>
                </div>
            </NavLink>
        </>
    );
}

export default NavItem;
