import IziToast from "izitoast";

export const successNotify = (text = "OK", options = {}) => {
    IziToast.success({
        message: text,
        timeout: 3000,
        ...options
    });
};
export const failureNotify = (text = "Error", timeout = 3000) => {
    IziToast.error({
        message: text,
        timeout
    });
};
