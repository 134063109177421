import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

const initialState: any = {
    data: '',
};

const navigateSlice = createSlice({
    name: 'navigate',
    initialState,
    reducers: {
        changeNavigateDropdownItem: (state, action: PayloadAction<string>) => {
            state.data = action.payload;
        },
    }
});
export const { changeNavigateDropdownItem } = navigateSlice.actions;

export const getNavigateData = (state: RootState) => state.navigate.data;

export default navigateSlice.reducer;
