import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import userReducer from './slices/userSlice';
import userEditSlice from "./slices/userEditSlice";
import personSlice from "./slices/personSlice";
import deviceSlice from "./slices/deviceSlice";
import navigateReducer from "./slices/navigationSlice";
import loadingReducer from "./reducers/loading/reducer";
import zoneSlice from "./slices/zoneSlice";

export const store = configureStore({
    reducer: {
        currentUser: userReducer,
        user: userEditSlice,
        person: personSlice,
        device: deviceSlice,
        zone: zoneSlice,
        loading: loadingReducer,
				navigate: navigateReducer
    }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
