import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import { $deleteDiscipline, $getListDiscipline } from "../../api/requests/discipline";
import DeleteDiscipline from "./DeleteDiscipline";
import PlusBorder from "../../img/icon/PlusBorder";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import Pen from "../../img/icon/Pen";
import CheckBox from "../../components/form/CheckBox";

const DisciplinePage: FC = () => {
    const isMobile = window.innerWidth < 465;
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('disciplines', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('disciplines', 'update');

    const [isChecked, setIsChecked] = useState(true);
    const [isCheckedActive, setIsCheckedActive] = useState(false);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [isCheckedPriority, setIsCheckedPriority] = useState(false);

    const [list, setList] = useState<any[]>([]);

    const navigate = useNavigate();

    const onNavigate = (link: string) => {
        navigate(link);
    };

    const [filterType, setFilterType] = useState<any>([
        { value: false, type: 'all' },
        { value: false, type: 'isActive' },
        { value: false, type: 'isCheckedAll' },
        { value: false, type: 'isPriority' }
    ]);

    const filteredItems = [...list].filter((item) => {
        const filterAll = filterType.find((filter: any) => filter.type === 'all').value;
        const filterIsActive = filterType.find((filter: any) => filter.type === 'isActive').value;
        const filterIsCheckedAll = filterType.find((filter: any) => filter.type === 'isCheckedAll').value;
        const filterIsPriority = filterType.find((filter: any) => filter.type === 'isPriority').value;

        if (filterAll) {
            return true
        }

        if (filterIsActive) {
            if (filterIsCheckedAll) {
                return item.isActive && !item.isRating;
            }
            if (filterIsPriority) {
                return item.isActive && item.isRating;
            }
            return item.isActive;
        }

        if (filterIsCheckedAll) {
            return !item.isRating;
        }
        if (filterIsPriority) {
            return item.isRating;
        }

        return true;
    });

    const handleBoxChange = (value: boolean, type: string) => {
        switch (type) {
            case 'all': {
                setIsChecked(true);
                setIsCheckedActive(false);
                setIsCheckedAll(false);
                setIsCheckedPriority(false);
                break;
            }
            case 'isActive': {
                setIsChecked(false);
                setIsCheckedActive(!isCheckedActive);
                break;
            }
            case 'isCheckedAll': {
                setIsChecked(false);
                setIsCheckedAll(!isCheckedAll);
                setIsCheckedPriority(false);
                break;
            }
            case 'isPriority': {
                setIsChecked(false);
                setIsCheckedAll(false);
                setIsCheckedPriority(!isCheckedPriority);
                break;
            }
            default:
                break;
        }
        onChangeFilterList(value, type);
    };

    useEffect(() => init(), []);

    function init() {
        $getListDiscipline().then(res => {
            if (!res.length) return;

            setList(res);
        })
    }

    function handleEdit(data: any) {
        navigate('edit', {
            state: {
                current: 'edit',
                data: data
            }
        });
    }

    function onChangeFilterList(value: boolean, type: string) {
        const filterTypeUpdate = [...filterType].map((item: any) => {
            if (item.type === type) return { value, type };

            if (type === 'all' && item.type !== 'all') {
                return { value: false, type: item.type }; // Отключаем все фильтры кроме 'all'
            }

            if (type !== 'all' && item.type === 'all') {
                return { value: false, type: 'all' }; // Отключаем фильтр 'all'
            }

            if ((type === 'isCheckedAll' || type === 'isPriority') && item.type !== type && (item.type === 'isCheckedAll' || item.type === 'isPriority')) {
                return { value: false, type: item.type }; // Отключаем противоположный фильтр
            }

            return item;
        });

        setFilterType(filterTypeUpdate);
    }

    function onDelete(itemToDelete: any) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteDiscipline(itemToDelete.id).then((res) => {
            if (!res.id) return;

            const updatedItems = list.filter((item) => item !== itemToDelete);

            setList(updatedItems);
        });
    }

    return (
        <div className="discipline list-wrapper">
            <div className="discipline-main">
                <div className="discipline__add">
                    <div className="booking__header">
                        <h1>Дисциплина</h1>
                    </div>
                    <div className="discipline__add__btn">
                        <div className="tariffs__header-checkboxes">
                            <CheckBox
                                isChecked={isChecked}
                                text={'Все'}
                                onChange={(value) => handleBoxChange(value, 'all')}
                            />
                            <CheckBox
                                isChecked={isCheckedActive}
                                text={'Активные'}
                                onChange={(value) => handleBoxChange(value, 'isActive')}
                            />
                            <CheckBox
                                isChecked={isCheckedAll}
                                text={'Общая'}
                                onChange={(value) => handleBoxChange(value, 'isCheckedAll')}
                            />
                            <CheckBox
                                isChecked={isCheckedPriority}
                                text={'Приоритетная'}
                                onChange={(value) => handleBoxChange(value, 'isPriority')}
                            />
                        </div>
                        {(isCreateByRole && userData?.staffCenter?.isMain) && (
                            <Button
                                className={'btn btn-primary'}
                                text={'Добавить дисциплину'}
                                onClick={() => onNavigate('create')}
                                leftIcon={<PlusBorder />}
                            />
                        )}
                    </div>
                    <div className="discipline__add__list">
                        <div className="row w-100">
                            { !!filteredItems.length && filteredItems.map((item, idx) => (
                                <div
                                    className="col-6 col-lg-5 col-sm-6 col-xl-4"
                                    key={`discipline-item-${idx}`}
                                >
                                    <div className="discipline__add__list__wrap">
                                        <div className="discipline__add__list__wrap__inner">
                                            <div className="discipline__add__list__wrap__inner__info">
                                                <div className="discipline__add__list__wrap__inner__info__img">
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${item.image})`
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="discipline__add__list__wrap__inner__info__txt">
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="discipline__add__list__wrap__inner__info__title">
                                                    <span>{item.subName}</span>
                                                </div>

                                                {(isUpdateByRole && userData?.staffCenter?.isMain) && (
                                                    <div className="discipline__add__list__wrap__inner__info__button">
                                                        <Button
                                                            className={'btn btn-primary btn__discipline'}
                                                            text={isMobile ? <Pen /> : 'Редактировать'}
                                                            onClick={() => handleEdit(item)}
                                                        />
                                                        <DeleteDiscipline
                                                            onDelete={() => onDelete(item)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>

                    { !filteredItems.length && (
                        <div className="program__block__item__appellation">
                            <p>Список дисциплин пуст</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DisciplinePage;

