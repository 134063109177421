import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import TextField from "../form/TextField";
import { ToggleBlock } from "../form/ToggleBlock";
import TransformObjArray from "../../mixins/transform-obj-array";
import { Editor } from "../form/Editor";
import ImageField from "../form/ImageField";
import ImageToBase64 from "../../mixins/image-to-base64";
import { $createNews, $getCategoryNews, $sendNotification, $updateNews } from "../../api/requests/news";
import { NewsCategoryModel } from "../../api/requests/news/interface";
import FilterSelect from "../FilterSelect";
import { $getListDiscipline } from "../../api/requests/discipline";
import RoleUsers from "../../mixins/role";

export const EditNews: FC = () => {
    const isMobile = window.innerWidth < 465
    const location = useLocation();
    const navigate = useNavigate();

    const imageToBase64 = new ImageToBase64();
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('newsMailing', 'create');

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();

    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'title',
            placeholder: 'Заголовок',
            title: 'Основная информация',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            required: true
        },
        {
            key: 'text',
            placeholder: 'Основной текст',
            value: [],
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            editor: true,
            paddingBottom: 30,
            required: true
        },
        {
            key: 'type',
            placeholder: 'Выберите тип',
            title: 'Тип новости',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            options: [
                {
                    label: 'Новость',
                    value: 0
                },
                {
                    label: 'Задание',
                    value: 1
                },
                {
                    label: 'Совет',
                    value: 2
                },
            ],
            required: true,
            disabled: false,
        },
        // {
        //     key: 'newsCategoryId',
        //     placeholder: 'Выберите Категорию',
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     select: true,
        //     options: [],
        //     required: false
        // },
        {
            key: 'disciplineId',
            placeholder: 'Дисциплина',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            options: [],
            required: false,
            hide: true
        },
        {
            key: 'subTitle',
            placeholder: 'Подзаголовок',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            required: true,
            hide: true
        },
        {
            key: 'notificationTitle',
            placeholder: 'Заголовок уведомления',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            required: false,
            hide: true
        },
        // {
        //     key: 'position',
        //     type: 'number',
        //     placeholder: 'Позиция новости',
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     textField: true,
        //     required: false
        // },
        {
            key: 'isActive',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            toggleText: 'Статус',
            checkbox: true,
            required: true
        },
        {
            key: 'sendPush',
            value: false,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            toggleText: 'Отправить пуш',
            checkbox: true,
            required: true
        },
        // {
        //     key: 'isPinned',
        //     value: false,
        //     setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
        //     toggleText: 'Закрепить новость',
        //     checkbox: true,
        //     required: false
        // },
    ]);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isNotification, setIsNotification ] = useState(false);
    const [ image, setImage ] = useState<any>(null);
    const [ isValidImage, setIsValidImage ] = useState<boolean>(false);

    useEffect(() => init(), []);

    function init() {
        $getCategoryNews().then(res => {
            if (!res.result) return;

            $getListDiscipline().then(_res => {
                if (!_res.length) return;

                const list = res.result && res.result.map((i: NewsCategoryModel) => ({
                    ...i,
                    label: i.title,
                    value: i.id
                }))

                const listDiscipline = _res.map((i) => ({
                    ...i,
                    label: i.name,
                    value: i.id
                }))

                const listUpdate = [ ...formFieldList ].map((i: any) => {
                    if (editedData && Object.keys(editedData).includes(i.key)) {
                        i.value = editedData[i.key];

                        if (i.options) {
                            const findItem: any = i.options.find((_i: any) => _i.value === editedData[i.key])

                            i.value = findItem || '';
                        }

                        if (i.key === "type") {
                            i.disabled = true
                        }
                    }

                    if (i.key === "newsCategoryId") {
                        i.options = list
                        i.value = list && list.find((_i: any) => _i.id === editedData[i.key])
                    }

                    if (i.key === "disciplineId") {
                        i.options = listDiscipline
                        i.value = listDiscipline.find((_i: any) => _i.id === editedData[i.key])
                    }

                    if ([ 'subTitle', 'notificationTitle', 'disciplineId' ].includes(i.key)) {
                        const findType: any = formFieldList.find(_i => _i.key === 'type')

                        if (findType && findType.value) {
                            const value = findType.value.value

                            i.required = !!value;
                            i.hide = !value;

                            setIsNotification(!!value);
                        }
                    }

                    return i;
                });

                setFormFieldList(listUpdate)
            })
        })

        if (editedData.image) setImage(editedData.image);
    }

    function onChangeSetValue(value: string | boolean, key: string) {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (i.key === key) i.value = value;

            if (key === 'type') return changeDisabledFields(i, value)

            return i;
        });

        setFormFieldList(listUpdate)
    }

    function changeDisabledFields(item: any, value: any): any {
        if ([ 'subTitle', 'notificationTitle', 'disciplineId' ].includes(item.key)) {
            item.required = !!value.value;
            item.hide = !value.value;

            setIsNotification(!!value.value);
        }

        return item
    }

    function onClickBack() {
        navigate("/news");
    }

    function onClickEnter() {
        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            position: +transformObjArray.getRequestObj(formFieldList).position,
            image: imageToBase64.getImageCheckValid(image)
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, true)) {
            const obj = transformObjArray.formValidateByRequired(requestData, formFieldList)

            setIsValidImage(typeof obj === 'boolean' ? obj : false)

            return;
        }

        setIsLoading(true);

        if (isEditPage) return onEditData(requestData);

        onCreateSave(requestData);
    }

    function onCreateSave(request: any) {
        $createNews(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $updateNews(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            onClickBack();
        })
    }

    function onClickSendNotification() {
        if (!editedData.id) return;

        $sendNotification(editedData.id).then()
    }

    return (
        <div className='edit__news news__block'>
            <div className="edit__news-main">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='edit__news__header achievements__edit-header'>
                                <div className="edit__block__item__back">
                                    <Button
                                        text={!isMobile && 'Назад' }
                                        leftIcon={ (
                                            <ArrowLeft
                                                color={ '#3582F6' }
                                            />
                                        ) }
                                        onClick={ onClickBack }
                                        className={ 'btn btn-icon' }
                                    />
                                </div>

                                <div className="edit__news__header__item">
                                    <h2>{ isEditPage ? 'Редактирование' : 'Создание' } Новости</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row column'>
                        <div className='col-12 col-sm-7'>
                            <form className="achievements__edit-content">
                                { formFieldList.map((i, idx) => (
                                    <React.Fragment key={ `form-field-achievement-item-${ idx }` }>
                                        { i.title && (
                                            <div className='achievements__edit-title'>
                                                <p>{ i.title }</p>
                                            </div>
                                        ) }

                                        { (i.textField && !i.hide) && (
                                            <div
                                                className={ `${ i.paddingBottom ? 'achievements__edit-padding30px' : '' }` }>
                                                <TextField
                                                    className={ i.paddingBottom ? 'achievements__edit-comment' : '' }
                                                    placeholder={ i.placeholder }
                                                    // type={ i.type }
                                                    value={ i.value }
                                                    onChangeValue={ (value) => i.setValue(value, i.key) }
                                                />
                                            </div>
                                        ) }

                                        { (i.select && !i.hide) && (
                                            <FilterSelect
                                                className={ 'mb-2' }
                                                placeholder={ i.placeholder }
                                                value={ i.value }
                                                options={ i.options }
                                                onChange={ (_i) => i.setValue(_i, i.key) }
                                                disabled={ i.disabled }
                                            />
                                        ) }

                                        { i.editor && (
                                            <div className='news__editor'>
                                                <Editor
                                                    value={ i.value }
                                                    onChangeValue={ (value) => i.setValue(value, i.key) }
                                                />
                                            </div>
                                        ) }

                                        { i.checkbox && (
                                            <ToggleBlock
                                                desc={ i.toggleText }
                                                isChecked={ i.value }
                                                handleChange={ (_i) => i.setValue(_i, i.key) }
                                            />
                                        ) }
                                    </React.Fragment>
                                )) }

                                <div className="edit__block__item__edit">
                                    <div className={ 'achievements__edit-button' }>
                                        { (isEditPage && isNotification && isCreateByRole) && (
                                            <Button
                                                onClick={ onClickSendNotification }
                                                className={ 'btn btn-primary mt-0 mt-md-3 justify-content-center w-100' }
                                                text={ 'Отправить уведомление' }
                                            />
                                        ) }

                                        <Button
                                            loading={ isLoading }
                                            onClick={ onClickEnter }
                                            className={ 'btn btn-primary mt-0 mt-md-3 justify-content-center w-100' }
                                            text={ isEditPage ? 'Сохранить' : 'Создать' }
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="col-12 col-xl-4 col-sm-5 edit__news-style">
                            <div className="edit__news__right-content">
                                <div className="edit__news__header__item__cover">
                                    <h3>Обложка новости</h3>

                                    {/*<Info*/ }
                                    {/*    color={'#3582F6'}*/ }
                                    {/*/>*/ }
                                </div>

                                <ImageField
                                    label={ '960x540' }
                                    upload={ image }
                                    setUpload={ setImage }
                                />

                                { isValidImage && (
                                    <p className={ 'mt-2' } style={ {color: 'red'} }>Обложка обязательно для
                                        заполнения</p>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
