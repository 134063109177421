import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import PlusBorder from "../../img/icon/PlusBorder";
import { useNavigate } from "react-router-dom";
import ClubItemInfo from "../../components/clubs/ClubItemInfo";
import { useSelector } from "react-redux";
import { changeUser, GetCurrentUserData } from "../../redux/slices/userSlice";
import { $getSelfStaffList, $selectCenterStaff } from "../../api/requests/staff";
import { useAppDispatch } from "../../redux/store";
import RoleUsers from "../../mixins/role";
import { addToast } from "../../api/handler";

const ClubsPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userData = useSelector(GetCurrentUserData);

    const roleUsers = new RoleUsers();

    const isAddByRole = roleUsers.getModelRoleByType('center', 'create');
    const isRatingByRole = roleUsers.getModelRoleByType('ratingEdit', 'create');

    const [centerList, setCenterList] = useState<any[]>([]);
    const [isActiveId, setIsActiveId] = useState('');
    const [isLoadingSelect, setIsLoadingSelect] = useState(false);

    useEffect(() => {
        userData && init()
    }, [userData]);

    function init() {
        $getSelfStaffList().then((res) => {
            if (!res.length) return;

            const searchActive = userData && res.find(i => i.id === userData.centerId);

            setCenterList(res);
            searchActive && setIsActiveId(searchActive.id);

            !userData?.centerId && addToast('У вас не выбран центр, чтобы продолжить работу выберите центр', 'error', 5000);
        })
    }

    function onClickHome() {
        navigate("/dashboard");
    }

    function onClickRating() {
        navigate("rating-city");
    }

    function onClickAddNew() {
        navigate("create");
    }

    function setIsActiveItem(id: string) {
        setIsLoadingSelect(true);

        $selectCenterStaff(id).then(res => {
            setIsLoadingSelect(false);
            if (!res.id) return;

            setIsActiveId(id);

            dispatch(changeUser(res))
        })
    }

    return (
        <div className="clubs list-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="club__header">
                            {userData?.centerId && (
                                <div className={'clubs__header__back'}>
                                    <Button
                                        text={!isMobile && 'На главную'}
                                        leftIcon={(
                                            <ArrowLeft
                                                color={'#3582F6'}
                                            />
                                        )}
                                        onClick={onClickHome}
                                        className={'btn btn-icon'}
                                    />

                                    {isMobile && <h4>Мои центры</h4>}
                                </div>
                            )}

                            <div className={'club__header-block'}>
                                {!isMobile && <h4>Мои центры</h4>}

                                {/*{isRatingByRole && (*/}
                                {/*    <Button*/}
                                {/*        text={'Управление рейтингом городов'}*/}
                                {/*        onClick={onClickRating}*/}
                                {/*        className={'btn btn-primary'}*/}
                                {/*    />*/}
                                {/*)}*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="club__content ">
                            {centerList.map((item, idx) => (
                                <ClubItemInfo
                                    key={`club-center-item-${idx}`}
                                    data={item}
                                    isActive={isActiveId === item.id}
                                    setIsActive={setIsActiveItem}
                                    isLoadingSelect={isLoadingSelect}
                                />
                            ))}

                            { isAddByRole && (
                                <Button
                                    className={'btn btn-add-club'}
                                    onClick={onClickAddNew}
                                    leftIcon={(
                                        <PlusBorder color={'#3582F6'}/>
                                    )}
                                    text={'Добавить центр'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClubsPage;

