import { FC, FormEvent, useEffect, useState } from "react";
import ArrowLeft from "../../../img/icon/ArrowLeft";
import TextField from "../../form/TextField";
import Button from "../../form/Button";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../../FilterSelect";
import EditPersonList from "./EditPersonList";
import Close from "../../../img/icon/Close";
import EditPersonSelect from "./EditPersonSelect";
import { getPersonData } from "../../../redux/slices/personSlice";
import { useSelector } from "react-redux";
import DatePickerField from "../../form/DatePickerField";
import { $createUser, $deletePersonUser, $getPersonUser, $updatePersonUser } from "../../../api/requests/staff";
import LocationCurrent from "../../../mixins/location";
import { GetCurrentUserData } from "../../../redux/slices/userSlice";
import TransformObjArray from "../../../mixins/transform-obj-array";
import ImageToBase64 from "../../../mixins/image-to-base64";
import DateOperations from "../../../mixins/date-operation";
import PhoneInput from "../../form/PhoneInput";

const EditPerson: FC = () => {
    const [ eventPopup, setEventPopup ] = useState<MouseEvent | null>(null);

    const isMobile = window.innerWidth < 465
    const locationCurrent = new LocationCurrent();
    const navigate = useNavigate();

    const personData = useSelector(getPersonData);
    const userData = useSelector(GetCurrentUserData);

    const transformObjArray = new TransformObjArray();
    const imageToBase64 = new ImageToBase64();
    const dateOperations = new DateOperations();

    const [ formFieldList, setFormFieldList ] = useState<any[]>([
        {
            key: 'email',
            type: 'email',
            title: 'Email',
            placeholder: 'Email',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: true
        },
        {
            key: 'phone',
            minLength: 11,
            title: 'Телефон',
            placeholder: 'Телефон, 11 цифр',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            required: true,
            mask: '+0 000 000-00-00',
        },
        {
            key: 'password',
            title: 'Пароль',
            placeholder: 'Пароль, минимум 6 символов',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: false
        },
        {
            key: 'role',
            title: 'Роль',
            placeholder: 'Выберите',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            imgRight: (<Close/>),
            select: true,
            options: [
                // {value: 1, label: 'Главный администратор'},
                {value: 2, label: 'Оператор региона'},
                // {value: 3, label: 'Управляющий компании'},
                {value: 4, label: 'Управляющий центра'},
                {value: 5, label: 'Администратор'},
                {value: 6, label: 'Администратор зала'},
                {value: 7, label: 'Маркетолог'},
                {value: 8, label: 'Судья'},
                // {value: 9, label: 'Гость'},
                {value: 10, label: 'Qr'},
            ],
            required: true
        },
        {
            line: true,
            required: false
        },
        {
            key: 'surname',
            title: 'Фамилия',
            placeholder: 'Фамилия',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: true
        },
        {
            key: 'name',
            title: 'Имя',
            placeholder: 'Имя',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: true
        },
        {
            key: 'lastName',
            title: 'Отчество',
            placeholder: 'Отчество',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: false
        },
        {
            key: 'gender',
            title: 'Пол',
            placeholder: 'Пол',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            select: true,
            options: [
                {value: 1, label: 'Мужской'},
                {value: 2, label: 'Женский'},
            ],
            required: true
        },
        {
            line: true,
            required: false
        },
        {
            key: 'birthDate',
            title: 'Дата рождения',
            placeholder: 'Дата рождения',
            value: null,
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            datePicker: true,
            required: true
        },
        {
            line: true,
            required: false
        },
        {
            key: 'city',
            title: 'Город',
            placeholder: 'Город',
            value: '',
            setValue: (value: any, idx: number) => changeValueByForm(value, idx),
            textField: true,
            required: true
        },
        // {
        //     key: 'address',
        //     title: 'Адрес',
        //     placeholder: 'Адрес',
        //     value: '',
        //     setValue: (value: any, idx: number) => changeValueByForm(value, idx),
        //     textField: true,
        // },
        {
            line: true,
            required: false
        },
    ]);
    const [ titlePage, setTitlePage ] = useState<string>('Создание');
    const [ isEditPage, setIsEditPage ] = useState<boolean>(false);
    const [ toggleValue, setToggleValue ] = useState<boolean>(false);
    const [ isDisabledDelete, setIsDisabledDelete ] = useState<boolean>(false);
    const [ fileImage, setFileImage ] = useState<any>(null);
    const [ personDataLocal, setPersonDataLocal ] = useState<any>(null);

    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate);
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);

    const getValue = (value: any): string => {
        if (typeof value === 'object') return value.value;

        return value || ''
    };

    const getTypeLocation = (name: string) => {
        const parts = name.split('/');

        const actionIndex = parts.indexOf('edit') !== -1 ? 'edit' : 'create';

        const idIndex = parts.indexOf(actionIndex) + 1;

        const id = parts[idIndex];

        return {
            type: actionIndex,
            id
        }
    }

    useEffect(() => init(), []);

    useEffect(() => {
        if (userData && (personData || personDataLocal)) {
            const personId = personData?.id || personDataLocal?.id;

            setIsDisabledDelete(personId === userData.staffCenter.userId);
        }
    }, [ userData, personData, personDataLocal ]);

    function init() {
        const {type, id} = locationCurrent.getTypeLocation(location.pathname);

        if (type === 'edit' && id) {
            setIsEditPage(true);
            setTitlePage('Редактирование')

            if (personData) {
                changeFormFields(personData)
            } else {
                $getPersonUser(id).then(res => {
                    if (!res.id) return;

                    setPersonDataLocal(res);
                    changeFormFields(res);
                })
            }
        }
    }

    const createFormFieldObject = (fieldList: any[]) => {
        return fieldList.reduce((acc, field) => {
            if (field.key && field.hasOwnProperty('value')) {
                if ((field.key === 'birthDate') && (field.value instanceof Date)) acc[field.key] = field.value.toLocaleDateString('en')
                else acc[field.key] = field.value ? getValue(field.value) : '';
            }
            return acc;
        }, {});
    };

    function changeFormFields(infoData: any) {
        setToggleValue(infoData.status > 0)

        setFormFieldList(list => {
            return list.map(i => {
                if (i.select) {
                    return {
                        ...i,
                        value: i.options.find((_i: any) => _i.value === infoData[i.key])
                    }
                }

                if (i.key === 'birthDate') {
                    return {
                        ...i,
                        value: new Date(infoData[i.key])
                    }
                }

                if (Object.keys(infoData).includes(i.key)) {
                    return {
                        ...i,
                        value: infoData[i.key] || ''
                    }
                }

                return i
            })
        })
    }

    function onClickHome() {
        navigate("/person");
    }

    function changeValueByForm(value: any, idx: number) {
        setFormFieldList((list) => {
            return list.map((i, index) => {
                if (idx === index) {
                    return {
                        ...i,
                        value
                    }
                }

                return i
            })
        })
    }

    function onClickCreate(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const date = transformObjArray.getRequestObj(formFieldList).birthDate;
        const phone = transformObjArray.getRequestObj(formFieldList).phone.replace(/[\s\-+]/g, '');

        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            // image: imageToBase64.getImageCheckValid(fileImage),
            status: toggleValue ? 1 : 0,
            phone,
            birthDate: (date instanceof Date)
                ? dateOperations.getISODateTimeByObj(date)
                : date
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, false))
            return console.log(transformObjArray.formValidateByRequired(requestData, formFieldList));

        $createUser(requestData).then(res => {
            if (!res.id) return;

            navigate('/person');
        })
    }

    function onClickSaveChange() {
        const {id} = getTypeLocation(location.pathname);

        const date = transformObjArray.getRequestObj(formFieldList).birthDate;
        const phone = transformObjArray.getRequestObj(formFieldList).phone.replace(/[\s\-+]/g, '');

        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            // image: imageToBase64.getImageCheckValid(fileImage),
            status: toggleValue ? 1 : 0,
            phone,
            birthDate: (date instanceof Date)
                ? dateOperations.getISODateTimeByObj(date)
                : date
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, false))
            return console.log(transformObjArray.formValidateByRequired(requestData, formFieldList));

        $updatePersonUser(id, requestData).then(res => {
            if (!res.id) return;

            navigate('/person');
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        const {id} = getTypeLocation(location.pathname);

        $deletePersonUser(id).then(res => {
            if (!res.id) return;

            navigate('/person');
        })
    }

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!target.classList.contains('btn')) {
            setEventPopup(null);
        }
    };

    function onClickOpenPopup(event: any) {
        setEventPopup(event);
    }

    return (
        <div className="edit__block person-edit style-edit">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="edit__block__header person-edit__header">
                            <div className="edit__block__item__back">
                                <Button
                                    text={!isMobile && 'Назад' }
                                    leftIcon={ (
                                        <ArrowLeft
                                            color={ '#3582F6' }
                                        />
                                    ) }
                                    onClick={ onClickHome }
                                    className={ 'btn btn-icon' }
                                />

                                {isMobile && (
                                    <h2>{ titlePage } сотрудника</h2>
                                )}
                            </div>

                            <div className="edit__block__item__header">
                                {!isMobile && (
                                    <h2>{ titlePage } сотрудника</h2>
                                )}

                                {/*<div*/ }
                                {/*    className={'person__popup'}*/ }
                                {/*    onMouseLeave={handleClick}*/ }
                                {/*    onMouseEnter={(event) => onClickOpenPopup(event)}*/ }
                                {/*>*/ }
                                {/*    <Info*/ }
                                {/*        color={'#3582F6'}*/ }
                                {/*    />*/ }

                                {/*    {eventPopup && (*/ }
                                {/*        <div*/ }
                                {/*            className={'person__popup-block'}*/ }
                                {/*            onMouseLeave={handleClick}*/ }
                                {/*        >*/ }
                                {/*            <h2 className={'person__popup-title'}>*/ }
                                {/*                Редактирование сотрудника*/ }
                                {/*            </h2>*/ }

                                {/*            <div className={'person__popup-desc'}>*/ }
                                {/*                <p>Создавайте и редактируйте сотрудников.</p>*/ }
                                {/*                <p>Переведите клиента в сотрудника. Просто начните вводить номер телефона и выберите в списке того клиента, которого хотите устроить в центр.</p>*/ }
                                {/*            </div>*/ }

                                {/*            /!*<p className={'person__popup-link'}><a href="">Подробнее</a></p>*!/*/ }
                                {/*        </div>*/ }
                                {/*    )}*/ }
                                {/*</div>*/ }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-7 col-sm-7">
                        <form className="edit__block__item" onSubmit={ (e) => onClickCreate(e) }>
                            { formFieldList.map((i, idx) => {
                                if (i.line) return (
                                    <div
                                        className="line"
                                        key={ `person-edit-block-form-${ idx }` }
                                    ></div>
                                )

                                return (
                                    <div className="edit__block__item__search"
                                         key={ `person-edit-block-form-${ idx }` }
                                    >
                                        { i.title && (
                                            <EditPersonList
                                                title={ i.title }
                                            />
                                        ) }

                                        { i.textField && (
                                            <TextField
                                                type={i.type}
                                                placeholder={ i.placeholder }
                                                value={ i.value }
                                                onChangeValue={ (value) => {
                                                    i.setValue(value, idx)
                                                } }
                                            />
                                        ) }

                                        { i.mask && (
                                            <PhoneInput
                                                placeholder={ i.placeholder }
                                                value={ i.value }
                                                onChangeValue={ (value) => {
                                                    i.setValue(value, idx)
                                                } }
                                                mask={i.mask}
                                            />
                                        ) }

                                        { i.select && (
                                            <FilterSelect
                                                placeholder={ i.placeholder }
                                                value={ i.value }
                                                options={ i.options || [] }
                                                onChange={ (value) => {
                                                    i.setValue(value, idx)
                                                } }
                                                imgRight={ i.imgRight }
                                            />
                                        ) }

                                        { i.datePicker && (
                                            <div className="form-group">
                                                <DatePickerField
                                                    startDate={ i.value }
                                                    placeholder={ 'Дата' }
                                                    onChangeDate={ (date) => i.setValue(date, idx) }
                                                    maxDate={eighteenYearsAgo}
                                                />
                                            </div>
                                        ) }
                                    </div>
                                )
                            }) }

                            <div className="edit__block__item__edit edit-style">
                                { isEditPage ? (
                                    <>
                                        <div className="edit__block__item__btn ">
                                            <Button
                                                className={ 'btn btn-danger w-100 justify-content-center' }
                                                text={ 'Уволить' }
                                                onClick={ onClickDelete }
                                                disabled={ isDisabledDelete }
                                            />
                                        </div>

                                        <div className="edit__block__item__btn ">
                                            <Button
                                                type={ "button" }
                                                className={ 'btn w-100 justify-content-center ' }
                                                text={ 'Изменить' }
                                                onClick={ onClickSaveChange }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="edit__block__item__btn">
                                        <Button
                                            type={ "submit" }
                                            className={ 'btn btn-primary w-100 justify-content-center' }
                                            text={ 'Создать' }
                                        />
                                    </div>
                                ) }
                            </div>
                        </form>
                    </div>

                    <div className="col-12 col-sm-5 col-xl-4 col-lg-5">
                        <EditPersonSelect
                            toggleValue={ toggleValue }
                            setToggleValue={ setToggleValue }
                            fileImage={ fileImage }
                            setFileImage={ setFileImage }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditPerson;
