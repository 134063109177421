import React, { FC, useEffect, useState } from "react";

import FilterSelect from "../FilterSelect";
import Button from "../form/Button";
import TextField from "../form/TextField";
import { DeviceModel } from "../../api/requests/device/interface";
import { $getCurrentZone } from "../../api/requests/zone";
import { $getCurrentAvailable } from "../../api/requests/device";

interface DialogMapDevicesAddProps {
	zoneId: string | null
    isType: number
    item?: any
    isEdit?: boolean
    onSave?: (item: any) => void
    deviceList?: DeviceModel[]
	groupList?: any[]
    isLoadingRequest?: boolean
    setIsLoadingRequest?: (is: boolean) => void
}

export const DialogMapDevicesAdd: FC<DialogMapDevicesAddProps> = (
    {
		zoneId,
        isType,
        item,
		groupList,
        isEdit,
        onSave,
        deviceList,
        isLoadingRequest,
        setIsLoadingRequest
    }
) => {

	const zones = [
		{value: 0, label: 'ПК'},
		{value: 1, label: 'Консоль'},
		{value: 2, label: 'VR'},
		// {value: 3, label: 'Спортивные зоны'}
	];

    const [group, setGroup] = useState([{}]);
    const [selectGroup, setSelectGroup] = useState<any>(null);
    const [positionValue, setPositionValue] = useState<string>('');

	const [valueGroupByZone, setValueGroupByZone] = useState<any>(null);
    const [valueZone, setValueZone] = useState<any>(null);

	const [ isShowDeviceBlock, setIsShowDeviceBlock ] = useState<boolean>(false);

	useEffect(() => {
		if (isType || isType === 0) {
			const findZone = zones.find(item => item.value === isType)

			onChangeZoneItem(findZone)
		}
	}, []);

    useEffect(() => {
        if (deviceList) {
            const mapDeviceList = deviceList.map(i => ({
                ...i,
                label: i.name,
                value: i.id
            }))

            setGroup(mapDeviceList);

            if (isEdit) {
                const findSelectItem = mapDeviceList.find(i => i.id === item.id);
                const findItem = zones.find(i => i.value === item.type);

				if (findItem && findSelectItem) {
					onChangeZoneItem(findItem);
					setIsShowDeviceBlock(true);
					setTimeout(() => setSelectGroup(findSelectItem), 500)
					setTimeout(() => setPositionValue(String(findSelectItem.position)), 500)
				}
            }
        }
    }, [isEdit]);

	function onChangeZoneItem(select: any) {
		setValueZone(select);

		setValueGroupByZone(null);
		setIsShowDeviceBlock(false);

		if (zoneId) {
			const findGroup = groupList && groupList.find((i: any) => i.id === zoneId);

			if (findGroup) {
				setValueGroupByZone(findGroup);
				onChangeGroupItem(findGroup);
			}
		}

		if (item) {
			const findGroup = groupList && groupList.find((i: any) => i.id === item.zoneId);

			if (findGroup) {
				setValueGroupByZone(findGroup);
				onChangeGroupItem(findGroup);
			}
		}
	}

	function onChangeGroupItem(select: any) {
		setValueGroupByZone(select);
		setIsShowDeviceBlock(false);
		setSelectGroup(null);

		if (deviceList) {
			const mapDeviceList = [...select.devices]
				.filter(i => i.type === select.type)
				.map(i => ({
					...i,
					label: i.name,
					value: i.id
				}))

			setGroup(mapDeviceList);
			setIsShowDeviceBlock(true);

			if (isEdit) {
				const findItem = mapDeviceList.find(i => i.id === select.id);

				findItem && setSelectGroup(findItem);
			}
		}
	}

    function onClickSave() {
        setIsLoadingRequest && setIsLoadingRequest(true);

        const requestData = {
            deviceId: selectGroup?.id,
            position: +positionValue,
            type: valueGroupByZone.type,
			x: item.x,
			y: item.y,
        }

        onSave && onSave(requestData)
    }

    return (
		<div className='added__booking__dialog'>
			<div className={'added__booking__dialog-setting-zone'}>
				<FilterSelect
					value={selectGroup}
					options={group}
					onChange={setSelectGroup}
					placeholder={'Выберите устройсво'}
				/>
			</div>

			<div className={'added__booking__dialog-setting-zone'}>
				<TextField
					type={'number'}
					label={ 'Позиция' }
					value={ positionValue }
					onChangeValue={ setPositionValue }
					maxLength={99}
					minLength={1}
				/>
			</div>

			<Button
				loading={isLoadingRequest}
				className={'btn btn-primary map-center__saveBtn'}
				text={isEdit ? 'Изменить устройство' : 'Добавить устройство'}
				onClick={onClickSave}
			/>
		</div>
	)
}
