import React, { FC, ReactNode, useState } from "react";
import ArrowTable from "../../img/icon/ArrowTable";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import Spinner from "../Spinner";

export interface DataItem {
    [key: string]: string | number | ReactNode;
}

export interface TableColumn {
    key: keyof DataItem;
    title: string;
    width?: string;
}

interface TableContainerProps {
    header: TableColumn[]
    content: DataItem[]
    setData?: (list: DataItem[]) => void
    sortable?: boolean
    textContentEmpty?: string | ReactNode
    onRightClickTrContent?: (e: MouseEvent, item: DataItem) => void
    onLeftClickTrContent?: (e: DataItem) => void
    isLastRightText?: boolean
    loading?: boolean
    tableBodyUnderBlock?: any
}

const TableContainer: FC<TableContainerProps> = (
    {
        header,
        content,
        setData,
        sortable = true,
        textContentEmpty = 'Пусто',
        onRightClickTrContent,
        onLeftClickTrContent,
        isLastRightText = true,
        loading = false,
        tableBodyUnderBlock
    }
) => {
    const [ sortConfig, setSortConfig ] = useState<{ key: keyof DataItem; direction: string } | null>(null);

    const location = useLocation();

    const handleSort = (key: keyof DataItem) => {
        if (!sortable) return;

        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});

        const sortedData = [ ...content ].sort((a: any, b: any) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setData && setData(sortedData);
    };

    return (
        <table className="table">
            <thead className="table__header">
                <tr>
                    {header.map((column, idx) => (
                        <th
                            key={column.key}
                            style={{ width: column.width }}
                            className={((idx === (header.length - 1)) && isLastRightText) ? 'last' : ''}
                        >
                            <div className={'table__header__th'}>
                                <p>{ column.title }</p>

                                {sortable && (
                                    <button
                                        onClick={ () => handleSort(column.key) }
                                        className={'btn btn-icon'}
                                    >
                                        { sortConfig?.direction && sortConfig.direction === 'asc' ? (
                                            <ArrowTable/>
                                        ) : (
                                            <ArrowTable/>
                                        ) }
                                    </button>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody className={`table__content ${loading ? 'table__content-loading' : ''}`}>
                { content.length ? content.map((item, index) => (
                    <React.Fragment key={ `table-content-item-${ index }` }>
                        <tr
                            key={ index }
                            onContextMenu={(e: any) => {
                                onRightClickTrContent && onRightClickTrContent(e, item)
                            }}
                            onClick={() => {
                                onLeftClickTrContent && onLeftClickTrContent(item)
                            }}
                        >
                            { header.map((column, idx) => (
                                <td
                                    key={ column.key }
                                    colSpan={idx === (header.length - 1) ? header.length : 1}
                                    style={{ width: column.width, overflow: 'hidden' }}
                                    className={((idx === (header.length - 1)) && isLastRightText) ? 'last' : ''}
                                >
                                    <div>
                                        { item[column.key] }
                                    </div>
                                </td>
                            )) }
                        </tr>

                        {tableBodyUnderBlock && tableBodyUnderBlock(item)}
                    </React.Fragment>
                )) : (
                    <tr>
                        <td
                            colSpan={header.length}
                        >
                            <div className={cn(
                                location.pathname === '/reports/user' ? 'reports__user-text' : 'name--content__text')}
                            >
                                {loading ? (
                                    <>
                                        <div className="vertical-center" style={{ position: 'relative' }}>
                                            <Spinner />
                                        </div>
                                    </>
                                ) : <p>{textContentEmpty}</p>}
                            </div>
                        </td>
                    </tr>
                ) }
            </tbody>
        </table>
    )
}

export default TableContainer;
