import React, { FC, useEffect, useRef, useState } from "react";

import Person from "../../img/icon/Person";

import Button from "../form/Button";

import { ShiftClose } from "../dialog/ShiftClose";
import DialogContainer from "../dialog/DialogContainer";
import { ShiftOpen } from "../dialog/ShiftOpen";
import { ShiftCloseSecond } from "../dialog/ShiftCloseSecond";
import { GetCurrentUserData, resetUser } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import Exit from "../../img/icon/Exit";
import Cookies from "js-cookie";
import { useAppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Accept from "../../img/icon/Accept";
import UserComplete from "../../img/icon/UserComplete";
import Collector from "../../img/icon/Collector";
import { OpenCollector } from "../dialog/CollectorOpen";
import RoleUsers from "../../mixins/role";
import { successNotify } from "../../notifications";

interface HeaderAsideProps {
    username: string,
    id: number,
    job: string,
    time: string
    onClose: () => void
    isOpen: boolean
}

export const HeaderAside: FC<HeaderAsideProps> = (
    {
        username,
        id,
        job,
        onClose,
        isOpen,
        time,
    }
) => {
    const userData = useSelector(GetCurrentUserData);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('collector', 'create');
    const isOpenShiftByRole = roleUsers.getModelRoleByType('shift', 'create');

    const popupRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    const [ isShowDialog, setIsShowDialog ] = useState<boolean>(false);
    const [ isShowPasswordDialog, setIsShowPasswordDialog ] = useState<boolean>(false);
    const [ isOpenShift, setIsOpenShift ] = useState<boolean>(false);
    const [ isCollector, setIsCollector ] = useState<boolean>(false);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ isOpen ]);

    useEffect(() => {
        setIsOpenShift(userData?.status === 2)
    }, [ userData ])

    useEffect(() => init(), [])

    function init() {}

    function openDialog(is?: boolean) {
        setIsShowDialog(is || false)
    }

    function onClickCollector(is?: boolean) {
        setIsCollector(is || false)
    }

    return (
        <>
            { isOpen && (
                <div className='header__aside' ref={ popupRef }>
                    <div className='header__aside-accounts'>
                        <div className='header__aside-icon'>
                            <Person color={ '#ffffff' }/>
                        </div>

                        <div className='header__aside-username'>
                            <p className='header__aside-title'>{ userData?.name || '' }</p>
                        </div>
                    </div>

                    {/*<div className='header__aside-numbers'>*/ }
                    {/*    <p className='header__aside-title'>*/ }
                    {/*        Сейчас смене*/ }
                    {/*    </p>*/ }

                    {/*    <p className='header__aside-paragraph'>{ username }</p>*/ }
                    {/*    <p className='header__aside-btn'>{ job }</p>*/ }

                    {/*    <div className='header__aside-ids'>*/ }
                    {/*        <p className='header__aside-paragraph_active'>{ id }</p>*/ }
                    {/*        <p className='header__aside-paragraph'>{ id }</p>*/ }
                    {/*    </div>*/ }
                    {/*</div>*/ }

                    <div className='header__aside-buttons'>
                        { (isOpenShiftByRole && userData?.centerId) && (
                            <>
                                { !isOpenShift ? (
                                    <Button
                                        className='header__aside-button'
                                        leftIcon={ <Accept/> }
                                        text={ 'В смену' }
                                        onClick={ () => {
                                            openDialog(true);
                                            onClose();
                                        } }
                                    />
                                ) : (
                                    <Button
                                        className='header__aside-button green'
                                        leftIcon={ <UserComplete color={ '#70D584' }/> }
                                        text={ 'Сдать смену' }
                                        onClick={ () => {
                                            openDialog(true);
                                            onClose();
                                        } }
                                    />
                                ) }
                            </>
                        ) }

                        { (isCreateByRole && userData?.centerId) && (
                            <Button
                                className='header__aside-button'
                                leftIcon={ <Collector/> }
                                text={ 'Инкассация' }
                                onClick={ () => {
                                    onClickCollector(true);
                                } }
                            />
                        ) }

                        <Button
                            className='header__aside-button red'
                            leftIcon={ <Exit color={ '#FF5858' }/> }
                            text={ 'Выйти' }
                            onClick={ () => {
                                Cookies.remove('token');
                                dispatch(resetUser());
                                navigate('/');
                            } }
                        />
                    </div>
                </div>
            ) }

            <DialogContainer
                isOpen={ isShowDialog }
                closeModal={ openDialog }
                classNameHeader={ 'shift-header' }
            >
                <div className="shift-header">
                    <div className='shift-title'>
                        <p className='shift-title'>{ isOpenShift ? 'Завершение смены' : 'Открытие смены' }</p>
                    </div>
                </div>

                { isOpenShift ? (
                    <ShiftClose
                        openNextDialog={ () => {
                            openDialog();
                            setIsShowPasswordDialog(true);
                        } }
                    />
                ) : (
                    <ShiftOpen
                        closeDialog={ () => {
                            openDialog();
                            setIsOpenShift(!isOpenShift);
							successNotify("Вступил в смену", { position: "topRight" });
                        } }
                    />
                ) }
            </DialogContainer>

            <DialogContainer
                isOpen={ isShowPasswordDialog }
                closeModal={ () => setIsShowPasswordDialog(!isShowPasswordDialog) }
                classNameHeader={ 'shift-header' }
            >
                <div className="shift-header">
                    <div className='shift-title'>
                        <p className='shift-title'>{ 'Завершение смены' }</p>
                    </div>
                </div>

                <ShiftCloseSecond
                    closeDialog={ () => {
                        setIsShowPasswordDialog(!isShowPasswordDialog);
                        setIsOpenShift(!isOpenShift);
                    } }
                />
            </DialogContainer>

            <DialogContainer
                isOpen={ isCollector }
                closeModal={ onClickCollector }
                classNameHeader={ 'collector-header' }
            >
                <div className="collector-header">
                    <div className="collector-title">
                        <p className='collector-title'>{ 'Инкассация' }</p>
                    </div>
                </div>

                <OpenCollector
                    closeCollector={ () => {
                        onClickCollector();
                        setIsCollector(false)
                    } }
                />

            </DialogContainer>

        </>
    )
}
