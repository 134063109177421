import { FC, useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from "../../mixins/uploadImageByEditor";

import '@ckeditor/ckeditor5-build-classic/build/translations/ru';

interface EditorProps {
    value?: any,
    onChangeValue?: (value: string) => void
}

export const Editor: FC<EditorProps> = ({value, onChangeValue}) => {
    const [ valueLocal, setValue ] = useState<string>(value);

    const onChangeLocalValue = (value: string) => {
        setValue(value);

        if (onChangeValue) onChangeValue(value);
    };

    useEffect(() => {
        if (value !== valueLocal) setValue(value)
    }, [ value ])

    return (
        <div className="App">
            <CKEditor
                // @ts-ignore
                editor={ ClassicEditor }
                data={ valueLocal }
                // onReady={ editor => {
                //     // You can store the "editor" and use when it is needed.
                //     // console.log('Editor is ready to use!', editor);
                // } }
                onChange={ (event: any, editor) => {
                    const data = editor.getData();
                    onChangeLocalValue(data)
                } }
                // onBlur={ (event, editor) => {
                //     console.log('Blur.', editor);
                // } }
                // onFocus={ (event, editor) => {
                //     console.log('Focus.', editor);
                // } }
                config={ {
                    extraPlugins: [ MyCustomUploadAdapterPlugin],
                    toolbar: [
                        'alignment',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                    ],
                    placeholder: 'Начните писать текст здесь...',
                    language: 'ru',
                    mediaEmbed: {
                        previewsInData: false,
                        // elementName: 'iframe',
                        // providers: [
                        //     {
                        //         name: 'myProvider', // https://www.youtube.com/watch?v=z_VYkUmJ5DM
                        //         url: /^.+/,
                        //         html: (url) => {
                        //             console.log(url)
                        //
                        //             const link = url.input || url[0]
                        //             const modifiedLink = link && link.replace('watch?v=', 'embed/');
                        //
                        //             //<iframe src="https://www.youtube.com/dOPbv35imQc"   frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div></figure>
                        //             //<iframe src="https://www.youtube.com/wembed/atch?v=dOPbv35imQc" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></figure>
                        //
                        //
                        //             // return '<div style="position:relative; padding-bottom:100%; height:0 padding-bottom: 56.2493%;">' +
                        //             //     `<iframe src="${modifiedLink}" frameborder="0" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"` +
                        //             //     'style="position:absolute; width:100%; height:100%; top:0; left:0">' +
                        //             //     '</iframe>' +
                        //             //     '</div>'
                        //             return `<iframe src="${modifiedLink}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>`;
                        //         }
                        //     },
                        // ]
                    }
                } }
            />
        </div>
    )
}
