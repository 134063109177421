import { FC, useEffect, useState } from "react";
import TextField from "./TextField";
import Button from "./Button";
import { $clientList } from "../../api/requests/users";
import { UsersGet } from "../../api/requests/users/interface";
import Close from "../../img/icon/Close";
import { withDebounce } from "../../functions";
import Token from "../../img/icon/Token";

interface Search {
    selectItem?: UsersGet
    onChange: (item: any) => void
    isShowBtnDelete?: boolean
}

export interface UserData {
    id: number
    name: string
    image: string
}

export const SearchItem: FC<Search> = (
    {
        selectItem,
        onChange,
        isShowBtnDelete = false
    }
) => {
    const [userValue, setUserValue] = useState<string>('');
    const [userImg, setUserImg] = useState<string>('/img/icon/sidebar/users-blue.svg');

    const [listUsers, setListUsers] = useState<UsersGet[]>([]);
    const [selectItemLocal, setSelectItemLocal] = useState<UsersGet | null>(selectItem || null);

    const [ isFocused, setIsFocused ] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const classEvent = (event.target as any).className;

            setIsFocused(['form-control left'].includes(classEvent))
        };

        document.addEventListener('click', handleDocumentClick);

        return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    useEffect(() => {
        if (!selectItemLocal || !selectItem) return;

        if (userImg !== (selectItemLocal.image || selectItem.image)) {
            setUserValue(selectItemLocal.name || selectItem.name)
            setUserImg(selectItemLocal.image || selectItem.image)
        }
    }, [selectItem])

    function onChangeValueName(value: string) {
        setUserValue(value);
        // clearItem();
        onChange('');

        $clientList({ search: value, page: '1' }).then(res => {
            if (res && !res.result) return;

            setListUsers(res.result)
        })
    }

    function onChangeSelectItem(item: UsersGet) {
        onChange(item);

        setUserValue(`${item.name}_${item.username}_${item.lastName}`);
        setUserImg(item.image);
        setSelectItemLocal(item);

        setListUsers([]);
    }

    function clearItem() {
        setSelectItemLocal(null);
        setUserValue('');
        setUserImg('/img/icon/sidebar/users-blue.svg');
    }

    function deleteItem() {
        clearItem();
        onChange(null);
    }

    return (
        <div className={'search__block'}>
            <div className="search__block-field">
                <TextField
                    value={userValue}
                    onChangeValue={(value) => withDebounce(() => onChangeValueName(value))}
                    placeholder={isShowBtnDelete ? 'Введите никнейм или номер телефона' : 'Выберите пользователя'}
                    imgLeft={(
                        <img src={userImg || '/img/logo/logo-2.png'} alt={''}/>
                    )}
                    className={`search__block-textField ${listUsers && 'active'}`}
                />

                {isShowBtnDelete && (
                    <div className={'search__block__clear'}>
                        <Button
                            text={(
                                <Close color={'#FF0000'} opacity={'1'}/>
                            )}
                            onClick={deleteItem}
                            className={'btn btn-icon'}
                        />
                    </div>
                )}
            </div>

            {(listUsers && (userValue && userValue.length) && isFocused) ? (
                <div className="search__block-dialog">
                    <div className="search__block-items">
                        {listUsers.map((item) => (
                            <div
                                className="search__block-item"
                                key={'search__block-item' + item.id}
                                onClick={() => onChangeSelectItem(item)}
                            >
                                <div className="search__block-img">
                                    <img src={item.image || '/img/avatars.png'}/>
                                </div>

                                <p>{item.name}_{item.username}_{item.lastName}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
