import { FC } from 'react';
import Calendar from "../../img/icon/Calendar";
import ClosedWindow from "../../img/icon/ClosedWindow";
import ElectroOff from "../../img/icon/ElectroOff";
import ElectroOn from "../../img/icon/ElectroOn";
import Exit from "../../img/icon/Exit";
import SmartShe from "../../img/icon/SmartShe";
import PeopleDouble from "../../img/icon/PeopleDouble";
import Unavailable from "../../img/icon/Unavailable";
import Pen from "../../img/icon/Pen";
import Reload from "../../img/icon/Reload";
import Execute from "../../img/icon/Execute";
import {
    $deleteDevice,
    $requestCommandByDevice,
    $startActionMaintenance,
    $stopSession
} from "../../api/requests/device";
import { CommandDevice } from "../../api/requests/device/interface";
import { useAppDispatch } from "../../redux/store";
import { changeDeviceItem } from "../../redux/slices/deviceSlice";
import RoleUsers from "../../mixins/role";
import Button from "../form/Button";
import Trash from "../../img/icon/Trash";

interface ComputersTableUnderBlockProps {
    data: any;
    setUpdateList: () => void;
    onOpenSelectTariff: () => void;
}

const ComputersTableUnderBlock: FC<ComputersTableUnderBlockProps> = (
    {
        data,
        setUpdateList,
        onOpenSelectTariff
    }
) => {
    const dispatch = useAppDispatch();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('fitZone', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('fitZone', 'update');
    const isDeleteByRole = roleUsers.getModelRoleByType('fitZone', 'delete');

    const isDisabledByRole = !isCreateByRole || !isUpdateByRole || !isDeleteByRole;

    const list = [
        {
            key: selectTariff,
            icon: <Calendar color={'#3582F6'}/>,
            name: 'Выбрать пакет',
            color: '#FFFFFF',
            disabled: ((data?.status !== 1) || isDisabledByRole),
        },
        {
            key: closeSession,
            icon: <ClosedWindow color={'#3582F6'}/>,
            name: 'Завершить сеанс',
            color: '#FF5858',
            disabled: ((data?.status !== 0) || isDisabledByRole)
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.RUN),
            icon: <ElectroOn color={'#3582F6'}/>,
            name: 'Включить Питание',
            color: '#70D584',
            disabled: data?.isOnline || isDisabledByRole,
            hide: data?.type !== 0,
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.SHUTDOWN),
            icon: <ElectroOff color={'#3582F6'} width={20} height={20}/>,
            name: 'Выключить Питание',
            color: '#FF5858',
            disabled: !data?.isOnline || isDisabledByRole,
            hide: data?.type !== 0,
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.REBOOT),
            icon: <Reload size={20}/>,
            name: 'Перезагрузить',
            disabled: !data?.isOnline || isDisabledByRole
        },
        {
            key: startActionMaintenance,
            icon: <ElectroOn color={data.status === -2 ? '#FF5858' : '#70D584'}/>,
            name: data.status === -2 ? 'Выкл. режим обслуживания' : 'Вкл. режим обслуживания',
            color: data.status === -2 ? '#FF5858' : '#70D584',
            disabled: ((data.status === 0) || isDisabledByRole),
            hide: data?.type !== 0,
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.LOGOFF),
            icon: <Exit color={'#FF5858'}/>,
            name: 'Выйти из системы',
            line: true,
            disabled: ((data?.status !== -2) || isDisabledByRole),
            hide: data?.type !== 0,
        },
        {
            key: '',
            icon: <SmartShe/>,
            name: 'Отправить логи',
            disabled: true,
            hide: data?.type !== 0,
        },
        {
            key: '',
            icon: <Execute/>,
            name: 'Выполнить',
            line: true,
            disabled: true,
            hide: data?.type !== 0,
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.ALLOW_ADMIN_MODE),
            icon: <PeopleDouble/>,
            name: 'Высокий доступ',
            disabled: ((data?.status !== -2) || isDisabledByRole),
            hide: data?.type !== 0,
        },
        {
            key: (item: any) => changeServiceCommand(item, CommandDevice.DISABLE_SHELL),
            icon: <Unavailable color={'#FF5858'}/>,
            name: 'Отключить шелл',
            disabled: ((data?.status !== -2) || isDisabledByRole),
            hide: data?.type !== 0,
        },
        {
            key: onClickEditDevice,
            icon: <Pen color={'#3582F6'}/>,
            name: 'Редактировать',
            line: true,
            disabled: ((data.status === 0) || !isUpdateByRole)
        },
        {
            key: onClickDeleteDevice,
            icon: <Trash color={'#3582F6'}/>,
            name: 'Удалить',
            disabled: ((data.status === 0) || !isDeleteByRole)
        }
    ]

    function closeSession(item: any) {
        $stopSession(item.id).then(res => {
            if (!res.id) return;

            setUpdateList()
        })
    }

    function onClickEditDevice(item: any) {
        dispatch(changeDeviceItem({
            ...item,
            name: item.name.props.children[0].props.children,
            user: item.user.props.children.props.children,
        }))
    }

    function onClickDeleteDevice(item: any) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteDevice(item.id).then(res => {
            setUpdateList()
        })
    }

    function selectTariff(item: any) {
        onOpenSelectTariff()
    }

    function changeServiceCommand(item: any, key: CommandDevice) {
        $requestCommandByDevice({
            deviceId: item.id,
            action: key
        }).then((res) => {
            setUpdateList()
        })
    }

    function startActionMaintenance(item: any) {
        $startActionMaintenance({
            deviceId: item.id,
            inService: data.status !== -2
        }).then(res => {
            setUpdateList()
        })
    }

    return (
        <tr className={'computers__content__table-under'}>
            <td colSpan={8}>
                <div className={'computers__content__table-under-block'}>
                    {list.map((item, idx) => {
                        if (item.disabled || item.hide) return null;

                        return (
                            <Button
                                key={`computers__under__block-${idx}`}
                                className={ 'btn btn-primary2' }
                                leftIcon={ item.icon }
                                text={ item.name }
                                onClick={ () => item.key && (item.key as any)(data) }
                                disabled={ item.disabled }
                            />
                        )
                    })}
                </div>
            </td>
        </tr>
    );
};

export default ComputersTableUnderBlock;
