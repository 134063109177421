import React, { FC, useState } from "react";
import Button from "../form/Button";
import TextField from "../form/TextField";
import { $shiftClose } from "../../api/requests/shift";
import { successNotify } from "../../notifications";

interface ShiftCloseSecondProps {
    closeDialog: () => void
}

export const ShiftCloseSecond: FC<ShiftCloseSecondProps> = (
    {
        closeDialog
    }
) => {
    const [ isLoadingRequestShiftClose, setIsLoadingRequestShiftClose ] = useState<boolean>(false);

    const [ valueComment, setValueComment ] = useState<string>('');
    const [ valueCash, setValueCash ] = useState<string>('');

    const getRequestCloseData = () => {
        return {
            "cashOnHandClose": +valueCash,
            "comment": valueComment
        }
    }

    function onCloseComplete() {
        setIsLoadingRequestShiftClose(true);

        $shiftClose(getRequestCloseData()).then((res) => {
            if (res.message) return;

            setIsLoadingRequestShiftClose(false);
			successNotify("Смена здана", { position: "topRight" });

            closeDialog();
        })
    }

    return (
        <div className='shift__close'>
            {/*<h1 className='shift__close-title'> Введите ваш пароль для завершения смены</h1>*/}

            <TextField
                className='shift__close-comment'
                type='number'
                label={ 'Введите сумму' }
                value={ valueCash }
                onChangeValue={ setValueCash }
            />

            <TextField
                className='shift__close-comment'
                label={ 'Комментарий' }
                value={ valueComment }
                onChangeValue={ setValueComment }
            />

            <Button
                loading={isLoadingRequestShiftClose}
                className='btn btn-primary shift__button'
                text={ 'Завершить смену' }
                onClick={ onCloseComplete }
            />
        </div>
    )
}
