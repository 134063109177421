import { FC, useState } from "react";
import Button from "../../components/form/Button";
import Setting from "../../img/icon/Setting";
import Trash from "../../img/icon/Trash";
import EditGroupDialog from "./EditGroupDialog";
import { $deleteZone } from "../../api/requests/zone";
import { useNavigate } from "react-router-dom";

interface ComputersTableHeaderProps {
    color: string
    title: string
    type: string
    item: any
    setUpdateList: () => void
    userData?: any
}

const ComputersTableHeader: FC<ComputersTableHeaderProps> = (
    {
        color,
        title,
        item,
        setUpdateList,
        type,
        userData
    }
) => {
	const navigate = useNavigate();

    const [isShowEditGroupsModal, setIsShowEditGroupsModal] = useState(false);

    function editBlock() {
        setIsShowEditGroupsModal(true);
    }

    function deleteBlock() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteZone(item.id).then(i => {
            if (i && !i.id) return;

            setUpdateList()
        })
    }

	function handleMapDevices() {
		navigate(`/map/devices?type=${item.type}&id=${item.id}`)
	}

    return (
        <div className="computers__content__table-header">
            <div className={'d-flex align-items-center flex-nowrap computers__header'}>
                {/*<span*/}
                {/*    style={{*/}
                {/*        backgroundColor: color*/}
                {/*    }}*/}
                {/*    className={'computers__content__table-header-color'}*/}
                {/*></span>*/}
                <h4>{title}</h4>

				{type !== "open-area" ? (
					<Button
						text={"Карта Устройств"}
						onClick={handleMapDevices}
						className={'btn btn-link ml-3'}
					/>
				) : null}
            </div>

            {(item.isEditable || userData?.role === 1) && (
                <div className="computers__content__table-header-btn">
                    <Button
                        text={<Setting/>}
                        onClick={editBlock}
                        className={'btn btn-icon'}
                    />

                    {item.isEditable && (
                        <Button
                            text={<Trash color={'#FF5858'}/>}
                            onClick={deleteBlock}
                            className={'btn btn-icon'}
                        />
                    )}
                </div>
            )}

            {isShowEditGroupsModal && (
                <EditGroupDialog
                    item={item}
                    isShow={isShowEditGroupsModal}
                    setIsShow={(item: any) => {
                        setIsShowEditGroupsModal(false);

                        item && setUpdateList()
                    }}
                    type={type}
                />
            )}
        </div>
    );
}

export default ComputersTableHeader;

