import React, { FC, useEffect, useState } from 'react';
import Button from "../form/Button";
import Accept from "../../img/icon/Accept";
import UserComplete from "../../img/icon/UserComplete";
import Collector from "../../img/icon/Collector";
import { ShiftClose } from "../dialog/ShiftClose";
import { ShiftOpen } from "../dialog/ShiftOpen";
import DialogContainer from "../dialog/DialogContainer";
import { OpenCollector } from "../dialog/CollectorOpen";
import { ShiftCloseSecond } from "../dialog/ShiftCloseSecond";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import RoleUsers from "../../mixins/role";
import { successNotify } from "../../notifications";

const HeaderMenu: FC = () => {
    const userData = useSelector(GetCurrentUserData);
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('collector', 'create');
    const isOpenShiftByRole = roleUsers.getModelRoleByType('shift', 'create');

    const [ isShowDialog, setIsShowDialog ] = useState<boolean>(false);
    const [ isCollector, setIsCollector ] = useState<boolean>(false);
    const [ isOpenShift, setIsOpenShift ] = useState<boolean>(false);
    const [ isShowPasswordDialog, setIsShowPasswordDialog ] = useState<boolean>(false);

    const [ nameUser, setNameUser ] = useState<string>('Имя Фамилия');
    const [ photoUser, setPhotoUser ] = useState<string>('/img/logo/logo-profile.svg');
    const [ roleUser, setRoleUser ] = useState<string>('Менеджер');

    useEffect(() => {
        if (userData) {
            setNameUser(`${ userData.surname || '' } ${ userData.name || '' } ${ userData.lastName || '' }`);
            userData?.image && setPhotoUser(userData.image);
            userData?.role && setRoleUser(roleUsers.getNameRoleUser(userData.role));
            userData?.status && setIsOpenShift(userData.status === 2)
        }

    }, [ userData ])

    function openDialog(is?: boolean) {
        setIsShowDialog(is || false)
    }

    function onClickCollector(is?: boolean) {
        setIsCollector(is || false)
    }

    return (
        <div className="header-menu">
            <div className={ 'header__profile' }>
                <div className="header__profile-logo">
                    <img src={photoUser} alt=""/>
                </div>

                <p className="header__profile-name">{ nameUser }</p>

                <div className="header__profile-type">
                    <p>{ roleUser }</p>
                </div>
            </div>

            <div className="header-menu-btn">
                <div className="header__aside-buttons">
                    { isOpenShiftByRole && (
                        <>
                            {!isOpenShift ? (
                                <Button
                                    className='header__aside-button'
                                    leftIcon={ <Accept/> }
                                    text={ 'В смену' }
                                    onClick={ () => {
                                        openDialog(true);
                                    } }
                                />
                            ) : (
                                <Button
                                    className='header__aside-button green'
                                    leftIcon={ <UserComplete color={ '#70D584' }/> }
                                    text={ 'Сдать смену' }
                                    onClick={ () => {
                                        openDialog(true);
                                    } }
                                />
                            )}
                        </>
                    ) }

                    { !isCreateByRole && (
                        <Button
                            className='header__aside-button'
                            leftIcon={ <Collector/> }
                            text={ 'Инкассация' }
                            onClick={ () => {
                                onClickCollector(true);
                            } }
                        />
                    ) }
                </div>
            </div>

            <DialogContainer
                isOpen={ isShowDialog }
                closeModal={ openDialog }
                classNameHeader={ 'shift-header' }
            >
                <div className="shift-header">
                    <div className='shift-title'>
                        <p className='shift-title'>{ isOpenShift ? 'Завершение смены' : 'Открытие смены' }</p>
                    </div>
                </div>

                { isOpenShift ? (
                    <ShiftClose
                        openNextDialog={ () => {
                            openDialog();
                            setIsShowPasswordDialog(true);
                        } }
                    />
                ) : (
                    <ShiftOpen
                        closeDialog={ () => {
                            openDialog();
                            setIsOpenShift(!isOpenShift);
							successNotify("Вступил в смену", { position: "topRight" });
                        } }
                    />
                ) }
            </DialogContainer>

            <DialogContainer
                isOpen={ isShowPasswordDialog }
                closeModal={ () => setIsShowPasswordDialog(!isShowPasswordDialog) }
                classNameHeader={ 'shift-header' }
            >
                <div className="shift-header">
                    <div className='shift-title'>
                        <p className='shift-title'>{ 'Завершение смены' }</p>
                    </div>
                </div>

                <ShiftCloseSecond
                    closeDialog={ () => {
                        setIsShowPasswordDialog(!isShowPasswordDialog);
                        setIsOpenShift(!isOpenShift);
                    } }
                />
            </DialogContainer>

            <DialogContainer
                isOpen={ isCollector }
                closeModal={ onClickCollector }
                classNameHeader={ 'collector-header' }
            >
                <div className="collector-header">
                    <div className="collector-title">
                        <p className='collector-title'>{ 'Инкассация' }</p>
                    </div>
                </div>

                <OpenCollector
                    closeCollector={ () => {
                        onClickCollector();
                        setIsCollector(false)
                    } }
                />

            </DialogContainer>
        </div>
    );
};

export default HeaderMenu;
