import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

const initialState: any = {
    data: null,
};

const zoneSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        changeZoneList: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        },
    }
});
export const { changeZoneList } = zoneSlice.actions;

export const getZoneData = (state: RootState) => state.zone.data;

export default zoneSlice.reducer;
