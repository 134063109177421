export default function MapCartIcon({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.7083 7.07973L12.4693 7.65724L12.7083 7.07973ZM16.3235 15.7338C16.6425 15.8657 17.008 15.7142 17.14 15.3952C17.272 15.0763 17.1204 14.7107 16.8015 14.5787L16.3235 15.7338ZM3.20772 8.95374C2.88877 8.82176 2.52322 8.97333 2.39124 9.29228C2.25926 9.61123 2.41083 9.97678 2.72978 10.1088L3.20772 8.95374ZM5.14706 3.125H14.8529V1.875H5.14706V3.125ZM14.8529 16.875H5.14706V18.125H14.8529V16.875ZM3.125 14.8529V5.14706H1.875V14.8529H3.125ZM5.14706 16.875C4.03031 16.875 3.125 15.9697 3.125 14.8529H1.875C1.875 16.66 3.33995 18.125 5.14706 18.125V16.875ZM16.875 14.8529C16.875 15.9697 15.9697 16.875 14.8529 16.875V18.125C16.66 18.125 18.125 16.66 18.125 14.8529H16.875ZM14.8529 3.125C15.9697 3.125 16.875 4.03031 16.875 5.14706H18.125C18.125 3.33995 16.66 1.875 14.8529 1.875V3.125ZM5.14706 1.875C3.33995 1.875 1.875 3.33995 1.875 5.14706H3.125C3.125 4.03031 4.03031 3.125 5.14706 3.125V1.875ZM16.875 9.0625V14.8529H18.125V9.0625H16.875ZM3.66728 4.01501L12.4693 7.65724L12.9473 6.50222L4.14522 2.85999L3.66728 4.01501ZM12.4693 7.65724L17.261 9.64001L17.739 8.48499L12.9473 6.50222L12.4693 7.65724ZM14.2988 2.67967L12.1542 6.79065L13.2624 7.36881L15.4071 3.25783L14.2988 2.67967ZM16.8015 14.5787L3.20772 8.95374L2.72978 10.1088L16.3235 15.7338L16.8015 14.5787ZM16.875 5.14706V9.0625H18.125V5.14706H16.875Z"
                fill={color}
            />
        </svg>
    );
}
